<template>
  <AppViewport
    class="App"
    :background="theme.windowBackground"
    :viewport-background="theme.background"
    :transition-name="transitionName">
    <router-view
      :key="$route.path"
      class="view" />
  </AppViewport>
</template>

<script>
import localsPreprocess from '@/locales-preprocess';
import soundService from '@/services/sound-service';

import sharedMessages from './i18n';
import AppViewport from './components/AppViewport';

import countdownHightSound from './sounds/countdown-tone-high.mp3';
import countdownLowSound from './sounds/countdown-tone-low.mp3';
import gameOverSound from './sounds/game-over-music.mp3';
import timeupSound from './sounds/timeup.mp3';
import gameHelper from '@/modules/games-shared/game-helper';

export default {
    components: { AppViewport },
    props: {
        gameId: {
            type: String,
            required: true
        }
    },
    provide() {
        return {
            theme: this.theme
        };
    },
    data() {
        return {
            transitionName: 'full_to_banner'
        };
    },
    watch: {
        $route(newRoute, oldRoute) {
            const fromHint = oldRoute.meta.transitionHint || 'none';
            const toHint = newRoute.meta.transitionHint || 'none';
            this.transitionName = `${fromHint}_to_${toHint}`;
        },
        '$store.state.moduleMemogenius.settings.backgroundSoundVolume': {
            immediate: true,
            handler(volume) {
                soundService.setLayerVolume('background', volume);
            }
        },
        '$store.state.moduleMemogenius.settings.effectSoundVolume': {
            immediate: true,
            handler(volume) {
                soundService.setLayerVolume('effect', volume);
            }
        }
    },
    beforeCreate() {
        const gameId = this.$options.propsData.gameId;
        const session = gameHelper.sessions[this.$attrs.sessionId];

        this.theme = (session && session.theme) || require('@/modules/game-' + gameId + '/theme').default;

        const sounds = {
            countdownHightSound,
            countdownLowSound,
            gameOverSound,
            timeupSound
        };
        if (this.theme.themeSound) {
            sounds.themeSound = this.theme.themeSound;
        }
        this.theme.sounds = sounds;
    },
    created() {
        this.$i18n.mergeLocaleMessage('no', {
            'memogenius-shared': localsPreprocess(sharedMessages).no,
            currentGame: localsPreprocess(this.theme.appMessages).no
        });
        this.$i18n.mergeLocaleMessage('en', {
            'memogenius-shared': localsPreprocess(sharedMessages).en,
            currentGame: localsPreprocess(this.theme.appMessages).en
        });
    }
};
</script>
